import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

export default function Kontakt({ data }) {
  const seo = {
    title: "Operacja plastyczna - jak przygotować się do zabiegu? | Dr Pastucha",
    description: "Przygotowanie pacjenta przed zgłoszeniem się do Kliniki Chirurgii Plastycznej. Szczegóły znajdziesz na naszej stronie www.",
    url: "https://mpastucha.pl/jak-przygotowac-sie-do-zabiegu"
  }

  return (
    <Layout headerClasses={'mainheader-subpage mainheader-white'}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <header id="top" className="fake-placeholder" />
      <a href="tel:913112228" className="phonelink"><img src="/images/ico-call.svg" alt="Ikona telefon" /></a>
      <section className="sct container about">
        <div className="row">
          <div className="col-10sm-center">
            <div className="row">
              <div className="col-4sm txt-h-dsc">
                <h1>Jak przygotować się do zabiegu?</h1>
              </div>
              <div className="col-12 flex-txt content-ul">
                <p><strong>Przygotowanie pacjenta przed zgłoszeniem się do Kliniki Chirurgii Plastycznej</strong></p>
                <p>Aby bezpiecznie przejść (nawet najdrobniejszy) zabieg operacyjny obowiązkowe jest wykonanie wcześniej podstawowych badań krwi, takich jak:<br /> w przypadku zabiegu wykonywanego w znieczuleniu miejscowym:</p>
                <ul className="content-ul">
                  <li>APTT,</li>
                  <li>przeciwciała anty-HCV,</li>
                  <li>antygen HBs-Ag</li>
                  <li>obecność wirusa HIVw przypadku zabiegu wykonywanego w znieczuleniu ogólnym:</li>
                </ul>
                <ul className="content-ul">
                  <li>grupa krwi</li>
                  <li>morfologia</li>
                  <li>układ krzepnięcia: APTT, INR, czas protrombinowy</li>
                  <li>jonogram</li>
                  <li>TSH</li>
                  <li>glukoza na czczo</li>
                  <li>antygen HBs-Ag</li>
                  <li>przeciwciała anty HCV</li>
                  <li>obecność wirusa HIV</li>
                </ul>
                <p><b>UWAGA: Na tydzień przed zabiegiem należy dostarczyć wyniki badań (osobiście, faxem lub e-mail)</b></p>
                <p>– wszystkie wynik badań ważne są 1 tydzień<br /> – pięć dni przed każdą operacją należy zażywać: Rutinoscorbin 3xdziennie oraz Arnicę 5 kulek pod język 5x dziennie<br /> – 2 tygodnie przed każdą operacją obowiązuje zakaz przyjmowania : vit.E, czosnku, kwasów omega-3 i omega-6, kwasu acetylosalicylowego (polopiryna, aspiryna, asprocol, acard i inne)<br /> – pacjent nie może być operowany w okresie infekcji<br /> – 48 godzin przed operacją nie wolno spożywać alkoholu<br /> – żadna operacja nie może się odbyć w okresie miesiączki (3 dni przed wystąpieniem krwawienia do 3 dni po ustąpieniu krwawienia)<br /> – w przypadku operacji w znieczuleniu ogólnym należy:<br /> 1. zgłosić się na czczo (bez jedzenia i picia)<br /> 2. zmyć makijaż oraz lakier z paznokci rąk<br /> 3. zabrać ze sobą piżamę oraz pantofle<br /> – przed operacją nosa należy stosować maść Bactroban 2xdziennie, zaczynając 3 dni przed planowaną datą zabiegu<br /> – przed operacją plastyki powłok brzucha i liftingiem twarzy obowiązuje zakaz palenia papierosów na 6 miesięcy przed planowaną datą zabiegu</p>
              </div>
            </div>
          </div>
        </div>
      </section>


    </Layout>
  )
}
